:root {
    --color-primary: rgb(96, 82, 163);
    --color-primary-light: rgb(230, 225, 245);
    --color-primary-blue-light: #62a2f6;
    --color-primary-pale-sky-blue: #E3F2FD;
    --color-orange: #fa8c16;
    --color-orange-hover: #d97612;
    /* Define más colores según sea necesario */
    --purple-base: #322D5A;

    /* Colors Base */
    --background-light-blue: #C7DEF7;
    --background-light-blue-2: #E9E9F2;
    --color-dark-slate-blue: #4C5570;
    --color-dark-slate-blue-hover: #3E465E;

    --color-red-base: rgb(255, 0, 0);
    --color-red-dark-1: rgb(204, 0, 0);
    /* 20% más oscuro */
    --color-red-dark-2: rgb(163, 0, 0);
    /* 40% más oscuro */
    --color-red-dark-3: rgb(130, 0, 0);
    /* 60% más oscuro */
    --color-red-dark-4: rgb(104, 0, 0);
    --gradient-red: linear-gradient(90deg, #ff4d4f, #ff7875);
    --gradient-red-hover: linear-gradient(90deg, #d9363e, #ff4d4f);
    /* 80% más oscuro */

    /* Colors Gradient Base */
    --gradient-purple: linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90));
    --gradient-purple-hover: linear-gradient(16deg, rgb(128, 110, 200), rgb(70, 60, 120));
    --gradient-red: linear-gradient(16deg, rgb(211, 47, 47), rgb(139, 23, 23));
    --gradient-red-hover: linear-gradient(16deg, rgb(239, 83, 80), rgb(165, 36, 36));
    --gradient-orange: linear-gradient(90deg, rgb(255, 165, 0), rgb(255, 140, 0));
    --gradient-orange-hover: linear-gradient(90deg, rgb(255, 140, 0), rgb(220, 100, 0));
    --gradient-green: linear-gradient(16deg, rgb(76, 175, 80), rgb(34, 139, 34));
    --gradient-green-hover: linear-gradient(16deg, rgb(129, 199, 132), rgb(46, 160, 46));
    --gradient-aqua-green: linear-gradient(16deg, rgb(179, 255, 217), rgb(128, 255, 178));
    --gradient-aqua-green-hover: linear-gradient(16deg, rgb(204, 255, 229), rgb(153, 255, 204));
    --gradient-water-blue: linear-gradient(16deg, rgb(102, 217, 255), rgb(0, 191, 255));
    --gradient-water-blue-hover: linear-gradient(16deg, rgb(153, 235, 255), rgb(51, 204, 255));
    --gradient-aqua-green-cloud: linear-gradient(16deg, rgb(230, 255, 241), rgb(210, 255, 228));
    --gradient-aqua-green-cloud-hover: linear-gradient(16deg, rgb(240, 255, 247), rgb(220, 255, 236));
    --gradient-water-blue-cloud: linear-gradient(16deg, rgb(230, 245, 255), rgb(210, 235, 255));
    --gradient-water-blue-cloud-hover: linear-gradient(16deg, rgb(240, 250, 255), rgb(220, 240, 255));
    /* Define más colores según sea necesario */


    /* Nuevos colores */

    --color-orange-button: #F7931E;
    --color-orange-button-hover: #e67c00;
    --color-orange-button-disabled: #fbc988;
    --color-orange-button-focus: #ffa733;
    /* ----------------------------------- */
    --color-orange-button-2: #A3C451;
    --color-orange-button-2-hover: #95aa3e;
    --color-orange-button-2-disabled: #D9E2AD;
    --color-orange-button-2-focus: #b1d35f;
    --color-purple-dark: #342D59;
    --color-purple-dark-2: #3B4459;
    --color-purple-dark-hover: #2E274F;
    /* Adjust this value as needed */
    --color-purple-dark-focus: #2B244A;
    /* Adjust this value as needed */
    --color-purple-dark-disabled: #4A426E;


    --color-dark-gray: #333;
    --color-light-blue: #F0F3FC;
    --color-deep-blue: #2E3192;
}

body {
    font-family: "Mulish", sans-serif;
    /* Otros estilos globales */
}

* {
    font-family: "Mulish", sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
    font-family: 'Mulish', sans-serif;
}

.ant-btn,
.ant-input,
.ant-menu,
.ant-dropdown-link,
.ant-select-selection-item {
    font-family: 'Mulish', sans-serif;
}

.font-project {
    font-family: 'Mulish';
}

.button-general {
    padding: 8px 16px;
    background: var(--gradient-purple) !important;
    color: #fff !important;
    border: none !important;
    border-radius: 20px !important;
    cursor: pointer;
}

.button-general:hover {
    background: var(--gradient-purple-hover) !important;
}

.button-general:disabled {
    background: var(--gradient-purple-hover) !important;
    opacity: 0.7;
}

.button-orange {
    padding: 8px 16px;
    background: var(--gradient-orange) !important;
    color: #fff !important;
    border: none !important;
    border-radius: 20px !important;
    cursor: pointer;
}

.button-orange:hover {
    background: var(--gradient-orange-hover) !important;
}

.button-orange:disabled {
    background: var(--gradient-orange-hover) !important;
    opacity: 0.7;
}

.button-orange:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 165, 0, 0.4);
}

.button-red {
    padding: 8px 16px;
    background: var(--gradient-red) !important;
    color: #fff !important;
    border: none !important;
    border-radius: 20px !important;
    cursor: pointer;
}

.button-red:hover {
    background: var(--gradient-red-hover) !important;
}

.button-red:disabled {
    background: var(--gradient-red-hover) !important;
    opacity: 0.7;
}

.button-red:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
}

.button-orange-v1 {
    margin-top: 5px;
    border-radius: 10px;
    min-width: 150px;
    background-color: var(--color-orange-button) !important;
    color: white;
    outline: none;
    border: none;
    transition: background-color 0.2s ease;
}

.button-orange-v1:hover {
    background-color: var(--color-orange-button-hover) !important;
    color: white !important;
}

.button-orange-v1:focus {
    background-color: var(--color-orange-button-focus) !important;
    color: white;
    box-shadow: 0 0 0 2px rgba(247, 147, 30, 0.3);
}

.button-orange-v1:disabled,
.button-orange-v1[disabled] {
    background-color: var(--color-orange-button-disabled) !important;
    color: white !important;
    cursor: not-allowed;
    opacity: 0.8;
}

.button-orange-v2 {
    margin-top: 5px;
    border-radius: 10px;
    min-width: 150px;
    background-color: var(--color-orange-button-2) !important;
    color: white;
    outline: none;
    border: none;
}

.button-orange-v2:hover {
    background-color: var(--color-orange-button-2-hover) !important;
    color: white !important;
}

.button-orange-v2:focus {
    background-color: var(--color-orange-button-2-focus) !important;
    color: white;
    box-shadow: 0 0 0 2px rgba(163, 196, 81, 0.3);
}

.button-orange-v2:disabled,
.button-orange-v2[disabled] {
    background-color: var(--color-orange-button-2-disabled) !important;
    color: white !important;
    cursor: not-allowed;
    opacity: 0.8;
}

.button-purple-dark {
    border-radius: 10px;
    min-width: 150px;
    background-color: var(--color-purple-dark) !important;
    color: white;
    outline: none;
    border: none;
}

.button-purple-dark:hover {
    background-color: var(--color-purple-dark-hover) !important;
    color: white !important;
}

.button-purple-dark:focus {
    background-color: var(--color-purple-dark-focus) !important;
    color: white;
    box-shadow: 0 0 0 2px rgba(163, 196, 81, 0.3);
}

.button-purple-dark:disabled,
.button-purple-dark[disabled] {
    background-color: var(--color-purple-dark-disabled) !important;
    color: white !important;
    cursor: not-allowed;
    opacity: 0.8;
}

.icon-select-button {
    font-size: 15px;
    color: var(--color-purple-dark) !important;
    font-weight: 700 !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-purple-dark);
    border-color: var(--color-purple-dark);
}

.custom-checkbox .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: var(--color-purple-dark);
    border-color: var(--color-purple-dark);
}

.custom-radio-purple .ant-radio-checked .ant-radio-inner {
    background-color: var(--color-purple-dark);
    border-color: var(--color-purple-dark);
}

.custom-radio-purple .ant-radio-checked:hover .ant-radio-inner {
    background-color: var(--color-purple-dark);
    border-color: var(--color-purple-dark);
}

.custom-radio-purple .ant-radio-checked .ant-radio-inner::after {
    background-color: white;
    /* punto interior */
}

.custom-radio-purple .ant-radio-wrapper-checked {
    color: var(--color-purple-dark);
}