.container-card-candidate {
    width: 98%;
    height: 90vh;
    border-radius: 10;
    border: "1px solid";
    box-shadow: 7px 7px 15px #00000016;
    border-color: "#00000020";
    margin-left: "1%";
    margin-right: "1%";
    margin-bottom: "0.5%";
    margin-top: "0.5%";
}

.container-button {
    width: "100%";
    padding-bottom: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: "10%";
}