.row-container-header-add-job-modal {
    width: 100%;
    height: 50px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    border-bottom: solid 1px var(--color-dark-slate-blue);
}

.div-header-title {
    height: 100%;
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.div-close-button-add-job-modal {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.div-title-add-job-modal {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.text-title-add-job-modal {
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: var(--color-purple-dark);
}

@media (max-width: 575.98px) {
    .div-title-add-job-modal {
        display: none;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .text-title-add-job-modal {
        display: none;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 880px) {
    .text-title-add-job-modal {
        display: none;
    }
}