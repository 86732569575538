.row-container-button-sourcing-sidebar {
    cursor: pointer;
    align-items: center;
    padding: 8px 0px 8px;
    min-height: 35px;
    width: 100%;
    display: flex;
    transition: background-color 0.3s ease;
}

/* Estado seleccionado */
.selected-sourcing {
    background-color: var(--color-orange);
}

/* Estado por defecto */
.default-sourcing {
    background-color: var(--background-light-blue-2);
}

/* Hover (solo para no seleccionados, opcional) */
.default-sourcing:hover {
    background-color: var(--color-orange-hover);
}

.col-image-sourcing {
    display: flex;
    justify-content: center;
}

.text-sourcing-button {
    font-size: 12px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: color 0.2s ease;
}

/* Texto seleccionado (blanco) */
.text-selected-sourcing {
    color: white;
}

/* Texto no seleccionado (negro por defecto) */
.text-default-sourcing {
    color: black;
}

/* Hover en texto no seleccionado */
.default-sourcing:hover .text-default-sourcing {
    color: white;
}

.col-counter-sourcing-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.div-counter-sourcing-button {
    min-width: 20px;
    /* para adaptarse a números largos */
    height: 20px;
    background-color: #D12525;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.text-counter-sourcing-button {
    font-size: 12px;
    font-weight: 600;
    color: white;
}