.floating-box-bottom-right {
    position: absolute;
    top: 170px;
    right: 20px;
    z-index: 10;
    background-color: white;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid black;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 500px;
    /* ancho fijo */
    box-sizing: border-box;
}

.floating-box-content {
    display: flex;
    flex-direction: column;
}

.floating-box-divider {
    margin: 16px 0;
    border: none;
    border-top: 1px solid black;
    width: 100%;
}

.floating-box-actions {
    display: flex;
    justify-content: space-between;
}

.text-title-floating-position-card {
    font-size: 20px;
    font-weight: 600;
    color: var(--color-deep-blue);
    box-sizing: border-box;
}

.text-title-description-floating-position-card {
    font-size: 20px;
    font-weight: 600;
    color: var(--color-dark-gray);
    margin-left: 20px;
}

@media (max-width: 575.98px) {
    .floating-box-bottom-right {
        position: absolute;
        top: 195px;
        right: 5%;
        z-index: 10;
        background-color: white;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid black;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        width: 90%;
        /* ancho fijo */
        box-sizing: border-box;
    }

    .floating-box-actions {
        display: flex;
        flex-direction: column;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .floating-box-bottom-right {
        position: absolute;
        top: 170px;
        right: 5%;
        z-index: 10;
        background-color: white;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid black;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        width: 90%;
        /* ancho fijo */
        box-sizing: border-box;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .floating-box-bottom-right {
        position: absolute;
        top: 170px;
        right: 5%;
        z-index: 10;
        background-color: white;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid black;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        width: 90%;
        /* ancho fijo */
        box-sizing: border-box;
    }
}