.row-section-workplace-job {
    width: 100%;
    background-color: var(--color-light-blue);
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    box-sizing: border-box;
}

.col-label-section-workplace-job {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;
}

.col-label-section-workplace-job .title {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}

.row-section-workplace-job .col-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-right: 20px;
    gap: 10px;
}





/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .row-section-workplace-job .col-select {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        box-sizing: border-box;
    }

    .autocomplete-job-workplace-section {
        box-sizing: border-box;
        width: 100%;
    }

    .row-section-workplace-job {
        box-sizing: border-box;
        display: flex;
        gap: 10px;
    }
}

/* Small devices (landscape phones, 576px and up) SM */
@media (min-width: 576px) and (max-width: 767.98px) {
    .row-section-workplace-job .col-select {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        box-sizing: border-box;
    }

    .autocomplete-job-workplace-section {
        box-sizing: border-box;
        width: 100%;
    }

    .row-section-workplace-job {
        box-sizing: border-box;
        display: flex;
        gap: 10px;
    }
}

/* Medium devices (tablets, 768px and up) MD*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .row-section-workplace-job .col-select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
    }

    .autocomplete-job-workplace-section {
        width: 150px;
    }

    .row-section-workplace-job {
        display: flex;
        gap: 10px;
    }
}