.div-section-skill-job {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
}

.input-skill-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px !important;
    background-color: #fff !important;

}

.div-input-skill {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    box-sizing: border-box;
}

.label-skill {
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}