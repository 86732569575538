.container-preview-job {
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    /* <-- esto es CLAVE */
}

.body-preview-job {
    flex: 1;
    box-sizing: border-box;
    overflow-y: auto;
    /* o cualquier fondo que quieras */
}