.label-skill {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: var(--color-dark-gray);
}

.input-skill-number-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px !important;
    background-color: #fff !important;
    height: 40px;

}

.button-cancel-skill {
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid #ff4d4f;
    color: #ff4d4f;
    height: 32px;
}

.autocomplete-job-skill-section {
    width: 100%;
}