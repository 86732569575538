.row-dashboard-sidebar-button {
    cursor: pointer;
    margin-top: 20px;
    height: 40px;
    align-items: center;
    padding: 8px 0px 8px;
    width: 100%;
    display: flex;
    /* por si necesitás alinear contenido interno */
    transition: background-color 0.3s ease;
}

/* Estilo por defecto */
.sidebar-default {
    background-color: var(--color-dark-slate-blue);
}

/* Estilo cuando está en el dashboard */
.sidebar-selected {
    background-color: var(--color-orange);
}

/* Hover sobre cualquier botón */
.row-dashboard-sidebar-button:hover {
    background-color: var(--color-orange-hover);
}

.col-image-dashboard-button {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-text-dashboard-button {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.text-dashboard-button {
    font-size: 14px;
    font-weight: 600;
    color: white;
}