.textTitleModalCreation {
    font-family: 'Mulish';
    font-size: 18px;
}



.div-container-add-job-modal {
    height: 80vh;
    padding: 0 40px;
}

.div-body-add-job-modal {
    height: calc(100% - 50px);
    width: 100%;
    overflow: auto;
}