.row-section-total-compensation-job {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--color-orange-button-2);
    padding: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    justify-content: center;
}

.title-total-compensation-job {
    font-size: 25px;
    font-weight: 600;
    color: white;
}