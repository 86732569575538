.div-title-section {
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 20px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background-color: var(--color-purple-dark-2);
}

.label-title-section {
    align-self: center;
    display: flex;
    font-size: 20px;
    font-weight: 400;
    color: white;
}