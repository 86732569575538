.carousel-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    padding: 20px 40px;
    box-sizing: border-box;
}

.carousel-content {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-align: center;
    transition: all 0.4s ease;
    position: relative;
    background-color: red;
    overflow: auto;
}

.carousel-dots {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-bottom: 20px;
}

.carousel-dots.top {
    position: relative;
    top: 0;
    margin-bottom: 20px;
}

.dot {
    width: 24px;
    height: 6px;
    border-radius: 3px;
    background-color: #ffccc7;
    /* rojo suave */
    cursor: pointer;
    transition: all 0.3s ease;
}

.dot.active {
    width: 32px;
    background-color: var(--color-dark-gray);
    /* rojo fuerte */
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    background-color: transparent;
    /* sin fondo */
    border: none;
    color: var(--color-dark-gray);
    /* rojo */
    cursor: pointer;
    z-index: 2;
    padding: 0;
}

.carousel-arrow.left {
    left: 10px;
}

.carousel-arrow.right {
    right: 10px;
}