.row-section-display-job {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;
}

.label-display-section {
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}

.col-label-section-display-job {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;

}

.row-section-display-job .col-checkbox {
    display: flex;
    align-items: center;
    box-sizing: border-box;
}