.row-section-update-job {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 4px;
    box-sizing: border-box;
}

.col-label-section-update-job {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;
}

.col-label-section-update-job .title {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}

.row-section-update-job .col-select {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-right: 20px;
}


.select-section-update-job {
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px !important;
    background-color: var(--color-light-blue) !important;
}

.autocomplete-job-title-section .ant-select-clear {
    color: var(--color-dark-gray) !important;
    font-size: 15px !important;
}


/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .row-section-update-job .col-select {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        box-sizing: border-box;
    }

    .select-section-update-job {
        box-sizing: border-box;
        width: 100%;
    }

    .row-section-update-job {
        box-sizing: border-box;
        display: flex;
        gap: 10px;
    }
}

/* Small devices (landscape phones, 576px and up) SM */
@media (min-width: 576px) and (max-width: 767.98px) {
    .row-section-update-job .col-select {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        box-sizing: border-box;
    }

    .select-section-update-job {
        box-sizing: border-box;
        width: 100%;
    }

    .row-section-update-job {
        box-sizing: border-box;
        display: flex;
        gap: 10px;
    }
}

/* Medium devices (tablets, 768px and up) MD*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .row-section-update-job .col-select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
    }

    .select-section-update-job {
        width: 150px;
    }

    .row-section-update-job {
        display: flex;
        gap: 10px;
    }
}