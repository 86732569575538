.data-not-found-card {
    align-items: center;
    animation: fadeInCard 0.5s ease-in-out;
    background-color: white;
    border-radius: 20px;
    border: 1px solid var(--color-primary-light);
    box-shadow: 0 2px 20px var(--color-primary-light);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow-y: auto;
    padding: 20px;
    padding-left: 350px;
    padding-right: 350px;
    width: 100%;
    /*  background-image: url('../../../assets/svg-assets/working-team.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; */
}

.color-text {
    color: black;

}

.data-not-found-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.data-not-found-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 15px;
}


@keyframes fadeInCard {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.icon-work-ant {
    max-width: 60px;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .data-not-found-text {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        gap: 10px;
        display: flex;
        padding-top: 250px;
        padding-bottom: 20px;
    }

    .data-not-found-card {
        align-items: center;
        animation: fadeInCard 0.5s ease-in-out;
        background-color: white;
        border-radius: 20px;
        border: 1px solid var(--color-primary-light);
        box-shadow: 0 2px 20px var(--color-primary-light);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        overflow-y: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        gap: 10px
    }

    .icon-work-ant {
        max-width: 100%;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .data-not-found-text {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        gap: 15px;
    }

    .icon-work-ant {
        max-width: 100%;
    }

    .data-not-found-card {

        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .icon-work-ant {
        max-width: 90%;
    }

    .data-not-found-card {

        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .icon-work-ant {
        max-width: 80%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .icon-work-ant {
        max-width: 100%;
    }
}