/* LoadingScreen.css */
.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    /* background: linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90)); */
    /* Fondo suave */
}

.loading-screen h2 {
    margin-top: 20px;
    font-size: 24px;
    color: white;
}