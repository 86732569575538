.row-section-salary-job {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 4px;
    box-sizing: border-box;
}

.col-label-section-salary-job {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;
}

.col-label-section-salary-job .title {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}

.label-salary-section {
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}

.col-label-section-max-salary-job {
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.col-label-section-max-salary-job .title {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}

.row-section-salary-job .col-input {
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.row-section-salary-job .col-checkbox {
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.input-job-salary-section {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px !important;
    background-color: #fff !important;
    background-color: var(--color-light-blue) !important;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {}

/* Small devices (landscape phones, 576px and up) SM */
@media (min-width: 576px) and (max-width: 767.98px) {
    .col-checkbox {
        margin-top: 30px;
    }

    .col-input {
        margin-top: 20px;
        padding-left: 20px;
    }

    .col-label-section-max-salary-job {
        margin-top: 20px;
        margin-left: 20px;
    }
}

/* Medium devices (tablets, 768px and up) MD*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .col-checkbox {
        margin-top: 30px;
    }
}

/* LG - ant design*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .col-checkbox {
        margin-top: 30px;
    }
}