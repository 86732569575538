.div-button-add-job {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 0px 8px;
    width: 100%;
    background-color: var(--color-dark-slate-blue);
    margin-top: 1px;
}

.div-button-add-job:hover {
    background-color: var(--color-dark-slate-blue-hover);
}