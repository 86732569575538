.label-other-details-compensation {
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}

.input-other-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px !important;
    background-color: var(--color-light-blue) !important;
}

.div-section-other-job {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    box-sizing: border-box;
}