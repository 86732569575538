.div-content-initial-step-add-job-modal {
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.row-title-initial-step-add-job-modal {
    display: flex;
    align-items: center;
    margin-top: 93px;
    box-sizing: border-box;
}

/* Start Form a previus job section */

.row-wrapper-select-job {
    position: relative;
}


.row-select-job-initial-step-add-job-modal {
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: 250px;
    background-color: var(--color-light-blue);
    border-radius: 20px;
}

.col-select-job-text-initial-step-add-job-modal {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
}

.col-select-job-initial-step-add-job-modal {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
}

.title-select-job-initial-step-add-job-modal {
    font-size: 22px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: var(--color-dark-gray);
}

.creation-modal-initial-page-button-select-job {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px !important;
    border: none !important;
    background-color: #fff !important;
}

.title-go-initial-step-add-job-modal {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: var(--color-purple-dark);
}




.button-go-initial-step {
    font-size: 18px;
    height: 40px;
    border-radius: 10px;
    width: 200px;
    color: white;
    background-color: var(--color-orange-button-2) !important;
}

.button-go-initial-step:hover {
    background-color: var(--color-orange-button-2-hover) !important;
    color: white !important;
}

.col-go-button-initial-step-add-job-modal {
    display: flex;
    justify-content: flex-end;
}

.col-text-initial-step-add-job-modal {
    display: flex;
    justify-content: flex-start;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .col-go-button-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
    }

    .col-text-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .button-go-initial-step {
        width: 100%;
    }

    .row-title-initial-step-add-job-modal {
        margin-top: 30px;
    }

    .row-select-job-initial-step-add-job-modal {
        display: flex;
        align-items: center;
        margin-top: 20px;
        height: auto;
        padding-top: 40px;
        box-sizing: border-box;
        padding-bottom: 40px;
        background-color: var(--color-light-blue);
        border-radius: 20px;
    }

    .col-select-job-text-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        padding: 10px
    }

    .col-select-job-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        padding: 10px
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .col-go-button-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
    }

    .col-text-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .button-go-initial-step {
        width: 245px;
    }

    .row-title-initial-step-add-job-modal {
        margin-top: 30px;
    }

    .row-select-job-initial-step-add-job-modal {
        display: flex;
        align-items: center;
        margin-top: 20px;
        height: auto;
        padding-top: 40px;
        box-sizing: border-box;
        padding-bottom: 40px;
        background-color: var(--color-light-blue);
        border-radius: 20px;
    }

    .col-select-job-text-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        padding: 10px
    }

    .col-select-job-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        padding: 10px
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .col-go-button-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
    }

    .col-text-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .button-go-initial-step {
        width: 245px;
    }

    .row-title-initial-step-add-job-modal {
        margin-top: 30px;
    }

    .row-select-job-initial-step-add-job-modal {
        display: flex;
        align-items: center;
        margin-top: 20px;
        height: auto;
        padding-top: 40px;
        box-sizing: border-box;
        padding-bottom: 40px;
        background-color: var(--color-light-blue);
        border-radius: 20px;
    }

    .col-select-job-text-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        padding: 10px
    }

    .col-select-job-initial-step-add-job-modal {
        display: flex;
        justify-content: center;
        padding: 10px
    }
}