.row-container-footer-add-job-modal {
    display: flex;
    width: 100%;
}

.col-buttons-add-job-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.previous-button-footer-add-job-modal {
    margin-top: 5px;
    border-radius: 10px;
    min-width: 150px;
    background-color: var(--color-orange-button) !important;
    color: white
}

.previous-button-footer-add-job-modal:hover {
    background-color: var(--color-orange-button-hover) !important;
    color: white !important;
}

.save-button-footer-add-job-modal {
    margin-top: 5px;
    border-radius: 10px;
    min-width: 150px;
    background-color: var(--color-dark-slate-blue) !important;
    color: white
}

.next-button-footer-add-job-modal {
    margin-top: 5px;
    border-radius: 10px;
    min-width: 150px;
    color: white
}

.next-button-footer-add-job-modal-active {
    background-color: var(--color-orange-button-2) !important;
}

.next-button-footer-add-job-modal-active:hover {
    background-color: var(--color-orange-button-2-hover) !important;
}

.next-button-footer-add-job-modal-inactive {
    background-color: var(--color-orange-button-2-disabled) !important;
}