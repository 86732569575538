.row-title-sourcing-sidebar {
    align-items: center;
    background-color: var(--color-dark-slate-blue);
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-bottom: 1px;
    margin-top: 1px;
    padding: 8px 0px 8px;
    width: 100%;
}

.col-image-sourcing-sidebar {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-text-sourcing-sidebar {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.text-sourcing-sidebar {
    color: white;
    font-size: 14px;
    font-weight: 600;
}