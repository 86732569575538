.div-work-condition-title {
    width: 100%;
    background-color: var(--color-light-blue);
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 20px;
}

.label-bonus-section {
    font-size: 20px;
    font-weight: 400;
    color: var(--color-dark-gray);
}

.div-work-condition-section {
    width: 100%;
    padding-top: 15px;
    gap: 10px;
    padding-bottom: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
    display: flex;
    padding-left: 20px;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .div-work-condition-section {
        flex-direction: column;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .div-work-condition-section {
        flex-direction: column;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}