.textTitle {
    font-size: 18px;
    color: #129BD4;
}

.textContents {
    font-size: 16px;
    color: #333;
    font-family: 'Mulish';

}